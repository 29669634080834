

















































































































































































































































































































































.rollCallSet {
  .btn_box {
    .tip_btn {
      background-color: #e36566;
      border-color: #e36566;
    }
  }
  .dialogtip {
    .el-dialog__body {
      padding: 20px;
    }
    .rollCall_inner {
      margin-bottom: 1rem;
      background-color: #f2f2f2;
      border-radius: 0.6rem;
      padding: 1.25rem 2rem;
      h3 {
        margin-bottom: 1rem;
      }
      p {
        margin-bottom: 1.3125rem;
        color: #666;
        font-size: 1rem;
        align-self: start;
      }
      img {
        width:100%;
      }
    }
    .rollCall_inner:last-child {
      margin: 0;
    }
    span.importColor {
      color: #5c6ce8;
    }
    span.w_100 {
      margin-top: 1rem;
      color: #666;
      font-size: 0.875rem;
      text-align: center;
      // height: 3.125rem;
      // width: 23.125rem;
    }
  }
}
